import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  Box, Button,
  FormControl,
  InputLabel,
  debounce,
} from '@mui/material';
import _ from 'lodash';
import {
  Dispatch, SetStateAction,
  useRef, useState,
} from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import Select, {
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  InputActionMeta, StylesConfig, components,
} from 'react-select';
import { Colors } from '../../../utils/colors';
import { FieldName } from '../../Project/style';
import { EStyleSelect } from '../SelectInput';

export interface InputSelectAsyncProps<FieldName, DataList> {
  label: string;
  required?: boolean;
  name: keyof FieldName;
  rules?: RegisterOptions;
  getOptionLabel: GetOptionLabel<DataList>;
  getOptionValue: GetOptionValue<DataList>;
  multiple?: boolean;
  isNewOne?: boolean;
  isLoading?: boolean;
  selectStyle?: EStyleSelect;
  data: DataList[];
  search: string;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

function SearchIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
      <path
        // eslint-disable-next-line max-len
        d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
        fill="currentColor"
      />
    </svg>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DropdownIndicator(props: any) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  );
}

export function InputSelectSearchAsync<FieldName, DataList>({
  label, required, name, rules,
  getOptionLabel, getOptionValue, multiple, isNewOne, search,
  setOpenModal, selectStyle, data, isLoading, setSearch,
}: InputSelectAsyncProps<FieldName, DataList>) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [inputText, setInputText] = useState<string>('');

  const handleSearchDebounced = useRef(
    debounce((text) => setSearch(text), 400),
  ).current;

  const handleInputChange = (text: string, meta: InputActionMeta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setInputText(text);
      handleSearchDebounced(text);
    }
  };
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <FormControl
      fullWidth
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {selectStyle === 'start' ? (
          <FieldName
            style={{
              width: '200px',
            }}
          >
            {label}

          </FieldName>

        ) : null}

        <Controller
          render={({ field }) => (
            <Box
              style={{
                width: '100%',
                display: 'flex',
              }}
            >
              <FormControl
                style={{ width: '100%' }}
                required={required}
                error={Boolean(_.get(errors, `${name as string}.ref`, false))}
              // helperText={_.get(errors, `${name as string}.message`, '') as string}
              >
                <InputLabel
                  htmlFor="movie-select"
                  required={required}
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    left: '12px',
                    transform: isFocused || field.value ? 'translate(0, -70%) scale(0.75) !important' : 'translate(0, 0) scale(1) !important',
                    transition: 'transform 0.2s ease-out',
                    pointerEvents: 'none',
                    backgroundColor: 'white',
                    padding: '2px',
                    color: isFocused ? Colors.orange : Colors.black60,
                    zIndex: 15,
                  }}
                >
                  {label}

                </InputLabel>
                <Select
                  id="movie-select"
                  value={field.value}
                  options={data}
                  isMulti={multiple}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  noOptionsMessage={() => null}
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  inputValue={inputText}
                  onInputChange={handleInputChange}
                  onChange={field.onChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder={isFocused ? 'Rechercher' : ''}
                  isLoading={!!search && isLoading}
                  filterOption={null}
                  styles={{
                    container: (p) => ({
                      ...p,
                      padding: 0,
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      border: _.get(errors, `${name as string}.ref`, false) ? '1px solid #d32f2f' : state.isFocused ? `2px solid ${Colors.orange}` : '1px solid #ced4da',
                      borderRadius: '4px',
                      boxShadow: 'unset',
                      zIndex: 10,
                      ':hover': {
                        borderColor: state.isFocused ? Colors.orange : 'unset',
                      },
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '1rem',
                    }),
                    input: (provided) => ({
                      ...provided,
                      fontSize: '1rem',
                    }),
                    valueContainer: (p) => ({
                      ...p,
                      fontSize: '1rem',

                    }),
                    menu: (p) => ({
                      ...p,
                      zIndex: 20,
                      fontSize: '1rem',
                    }),
                  } as StylesConfig<DataList, boolean, GroupBase<DataList>>}
                />
                {_.get(errors, `${name as string}.ref`, false) ? (
                  <Box
                    sx={{
                      color: '#d32f2f',
                      fontSize: '0.75rem',
                      lineHeight: 1.66,
                      letterSpacing: '0.03333em',
                      textAlign: 'left',
                      marginTop: '4px',
                      marginRight: '14px',
                      marginBottom: '0',
                      marginLeft: '14px',
                    }}
                  >
                    {_.get(errors, `${name as string}.message`, '') as string}
                  </Box>
                ) : null}
              </FormControl>

              {
                isNewOne && setOpenModal ? (
                  <Button
                    onClick={() => setOpenModal(true)}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </Button>
                ) : null
              }
            </Box>
          )}
          name={name as string}
          control={control}
          rules={{
            ...rules,
            required: {
              value: required as boolean,
              message: 'Ce champs est obligatoire',
            },
          }}
        />
      </div>

    </FormControl>

  );
}

InputSelectSearchAsync.defaultProps = {
  required: false,
  rules: {},
  multiple: false,
  isNewOne: false,
  setOpenModal: undefined,
  selectStyle: 'top',
  isLoading: false,
};
