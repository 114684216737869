/* eslint-disable react/jsx-props-no-spreading */
import { FormGroup, TextField, TextFieldProps } from '@mui/material';
import _ from 'lodash';
import {
  Controller,
  RegisterOptions, useFormContext,
} from 'react-hook-form';
import { FieldName } from '../../Project/style';

export enum ETypeInputText {
  // eslint-disable-next-line no-unused-vars
  TEXT = 'text',
  // eslint-disable-next-line no-unused-vars
  PASSWORD = 'password',
  // eslint-disable-next-line no-unused-vars
  EMAIL = 'email',
}
export enum EStyleInput {
  // eslint-disable-next-line no-unused-vars
  START = 'start',
  // eslint-disable-next-line no-unused-vars
  TOP = 'top',
}
export enum EGrow {
  // eslint-disable-next-line no-unused-vars
  default = 0,
  // eslint-disable-next-line no-unused-vars
  grow = 1,
}

type Props<T> = TextFieldProps & {
  label: string;
  required?: boolean;
  fullWidth?: boolean;
  name: keyof T;
  rules?: RegisterOptions;
  type?: ETypeInputText;
  selectStyle?: EStyleInput;
  disabled?: boolean;
  multiline?: boolean;
  grow?: EGrow;
}

export function InputText<T>({
  label,
  required,
  name,
  rules,
  type,
  fullWidth,
  selectStyle,
  disabled,
  multiline,
  grow,
  inputProps,
}: Props<T>) {
  const { formState: { errors }, control } = useFormContext();
  return (
    <FormGroup style={{ flexGrow: grow }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
        }}
      >

        {selectStyle === 'start' ? (
          <FieldName
            style={{
              width: '200px',
            }}
          >
            {label}

          </FieldName>

        ) : null}
        <Controller
          name={name as string}
          control={control}
          rules={{
            ...rules,
            required: {
              value: required as boolean,
              message: 'Ce champs est obligatoire',
            },
          }}
          render={({ field }) => (
            <TextField
              defaultValue={field.value}
              onChange={field.onChange}
              disabled={disabled}
              required={required}
              label={selectStyle === 'top' ? label : ''}
              type={type}
              multiline={multiline}
              inputProps={inputProps}
              size="small"
              fullWidth={fullWidth}
              error={Boolean(_.get(errors, `${name as string}.ref`, false))}
              helperText={_.get(errors, `${name as string}.message`, '') as string}
            />
          )}
        />
      </div>
    </FormGroup>
  );
}

InputText.defaultProps = {
  required: false,
  disabled: false,
  fullWidth: true,
  rules: {},
  type: ETypeInputText.TEXT,
  selectStyle: 'top',
  multiline: false,
  grow: EGrow.default,
};
