/* eslint-disable react/jsx-props-no-spreading */
import { FormGroup, TextField } from '@mui/material';
import _ from 'lodash';
import {
  RegisterOptions, useFormContext,
} from 'react-hook-form';

interface Props<T> {
  label: string;
  required?: boolean;
  name: keyof T;
  rules?: RegisterOptions;
}

export function TextArea<T>({
  label, required, name, rules,
}: Props<T>) {
  const { register, formState: { errors } } = useFormContext();
  return (
    <FormGroup
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <TextField
        {...register(name as string, {
          ...rules,
          required: {
            value: required as boolean,
            message: 'Ce champs est obligatoire',
          },
        })}
        required={required}
        label={label}
        rows={4}
        multiline
        error={Boolean(_.get(errors, [name, 'ref'], false))}
        helperText={_.get(errors, [name, 'message'], '') as string}
      />
    </FormGroup>
  );
}

TextArea.defaultProps = {
  required: false,
  rules: {},
};
